import './App.css';
import BirthdayCake    from './components/BirthdayCake';
import FireworkOverlay from './components/FireworkOverlay';
import { useState }    from 'react';
import { useEffect }   from 'react';

function App() {
    const fireworkDuration        = 6;
    const [showCake, setShowCake] = useState(false);

    useEffect(() => {
        const showCakeTimeout = setTimeout(
            () => {
                setShowCake(true);
            },
            (
                fireworkDuration - 1
            ) * 1000,
        );

        return () => {
            clearTimeout(showCakeTimeout);
        };
    }, []);

    return (
        <div className="App">
            <FireworkOverlay fadeOutDelay={fireworkDuration} />
            <BirthdayCake showCake={showCake} />
        </div>
    );
}

export default App;
