import React, { useState, useEffect } from 'react';
import Particles                      from 'react-particles';
import { loadFireworksPreset }        from 'tsparticles-preset-fireworks';
import { stringToRgb }                from 'tsparticles-engine';
import { rgbToHsl }                   from 'tsparticles-engine';
import { setRangeValue }              from 'tsparticles-engine';

function FireworkOverlay({ fadeOutDelay = 10 }) {
    const opacityFadeOut                    = 5;
    const [opacity, setOpacity]             = useState(1);
    const [showParticles, setShowParticles] = useState(true);

    useEffect(() => {
        const fadeOutTimeout = setTimeout(() => {
            setOpacity(0);

            const removeParticlesTimeout = setTimeout(() => {
                setShowParticles(false);
            }, opacityFadeOut * 1000);

            return () => {
                clearTimeout(removeParticlesTimeout);
            };
        }, fadeOutDelay * 1000);

        return () => {
            clearTimeout(fadeOutTimeout);
        };
    }, [fadeOutDelay, opacityFadeOut]);

    return showParticles && (
        <div style={{
            zIndex:          9999,
            width:           '100vw',
            height:          '100vh',
            position:        'fixed',
            top:             0,
            left:            0,
            transition:      `opacity 1.5s ease-in-out`,
            opacity,
        }}>
            <Particles
                options={{
                    preset:    'fireworks',
                    particles: {
                        destroy: {
                            split: {
                                particles: fireworksOptions,
                            },
                        },
                    },
                    sounds: {
                        enable: false,
                    },
                }}
                init={customInit}
            />
        </div>
    );
}

async function customInit(engine) {
    await loadFireworksPreset(engine);
}

const fixRange = (value, min, max) => {
    const diffSMax = value.max > max ? value.max - max : 0;
    let res        = setRangeValue(value);
    if (diffSMax) {
        res = setRangeValue(value.min - diffSMax, max);
    }
    const diffSMin = value.min < min ? value.min : 0;
    if (diffSMin) {
        res = setRangeValue(0, value.max + diffSMin);
    }
    return res;
};

const fireworksOptions = ['#ee9ca7', '#f3d1dc', '#f7bec7', '#f8c6d9']
    .map((color) => {
        const rgb = stringToRgb(color);
        if (!rgb) {
            return undefined;
        }
        const hsl = rgbToHsl(rgb), sRange = fixRange({ min: hsl.s - 30, max: hsl.s + 30 }, 0, 100), lRange = fixRange({ min: hsl.l - 30, max: hsl.l + 30 }, 0, 100);
        return {
            color:   {
                value: {
                    h: hsl.h,
                    s: sRange,
                    l: lRange,
                },
            },
            stroke:  {
                width: 0,
            },
            number:  {
                value: 0,
            },
            opacity: {
                value:     {
                    min: 0.1,
                    max: 1,
                },
                animation: {
                    enable:     true,
                    speed:      0.7,
                    sync:       false,
                    startValue: 'max',
                    destroy:    'min',
                },
            },
            shape:   {
                type: 'circle',
            },
            size:    {
                value:     { min: 1, max: 2 },
                animation: {
                    enable:     true,
                    speed:      5,
                    count:      1,
                    sync:       false,
                    startValue: 'min',
                    destroy:    'none',
                },
            },
            life:    {
                count:    1,
                duration: {
                    value: {
                        min: 1,
                        max: 2,
                    },
                },
            },
            move:    {
                decay:     { min: 0.075, max: 0.1 },
                enable:    true,
                gravity:   {
                    enable:       true,
                    inverse:      false,
                    acceleration: 5,
                },
                speed:     { min: 5, max: 15 },
                direction: 'none',
                outModes:  'destroy',
            },
        };
    })
    .filter((t) => t !== undefined);

export default FireworkOverlay;
