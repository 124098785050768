import React, { useState, useRef, useEffect } from 'react';
import { ReactComponent as Cake }             from './cake.svg';
import './index.css';
import ConfettiExplosion                      from 'react-confetti-explosion';

const BIRTHDAY_CAKE_ANIMATION_DURATION = 12;

function BirthdayCake({ name = 'Nici', showCake = true }) {
    const [downloadButtonOpacity, setDownloadButtonOpacity] = useState(0);
    const [isExploded, setIsExploded]                       = useState(false);
    const [confettiPosition, setConfettiPosition]           = useState({ x: 0, y: 0 });
    const cakeRef                                           = useRef(null);

    useEffect(() => {
        if (showCake) {
            setDownloadButtonOpacity(1);
        } else {
            setDownloadButtonOpacity(0);
        }

        if (cakeRef.current) {
            const rect = cakeRef.current.getBoundingClientRect();

            setConfettiPosition({ x: rect.x + rect.width / 2, y: rect.y + rect.height });
        }
    }, [showCake]);

    const handleCakeClick = () => {
        setIsExploded(true);
        setTimeout(() => setIsExploded(false), 5000);
    };

    const confettiColors = ['#ee9ca7', '#f3d1dc', '#f7bec7', '#f8c6d9'].map(color =>
        adjustColor(color, -20), // Darken each color slightly
    );

    return (
        <a
            style={{
                all:    'unset',
                cursor: 'pointer',
            }}
            href="https://download.joerndyherrn.de/uploads/Digitaler%20Lehrerkalendar.zip"
            download="filename"
        >
            {showCake && (
                <div
                    onClick={handleCakeClick}
                    ref={cakeRef}
                >
                    <div className="velas">
                        <div className="fuego"></div>
                        <div className="fuego"></div>
                        <div className="fuego"></div>
                        <div className="fuego"></div>
                        <div className="fuego"></div>
                    </div>
                    <Cake />
                    {isExploded && (
                        <ConfettiExplosion
                            force={1}
                            duration={3000}
                            particleCount={600}
                            colors={confettiColors}
                            width={window.innerWidth + 100}
                            style={{
                                position: 'absolute',
                                top:      confettiPosition.y,
                                left:     confettiPosition.x,
                                zIndex:   1,
                            }}
                        />
                    )}
                </div>
            )}
            <div className="text">
                <h1>Happy Birthday</h1>
                <p>{name}</p>
                {/*<a
                    href="https://download.joerndyherrn.de/uploads/Digitaler%20Lehrerkalendar.zip"
                    download="filename"
                    className="download-button"
                    style={{
                        opacity:    downloadButtonOpacity,
                        transition: `opacity ${BIRTHDAY_CAKE_ANIMATION_DURATION}s ease-in-out`,
                    }}
                >
                    Download
                </a>*/}
            </div>
        </a>
    );
}

const adjustColor = (color, amount) => {
    return '#' + color.replace(/^#/, '').replace(/../g, color =>
        (
            '0' + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)
        ).substr(-2),
    );
};

export default BirthdayCake;
